import { Div } from '../../styles';
import 'flatpickr/dist/flatpickr.css';
import Flatpickr, { DateTimePickerProps } from 'react-flatpickr';
import styled from 'styled-components';

const AdminDatePickerWrapperStyled = styled.div`
  position: relative;
  gap: 16px;
  font-size: 15px;
  line-height: 18px;
  color: ${({ theme }) => theme.darkGray};
  display: inline-block;
  padding: 12px 12px 16px 12px;
  border: ${({ theme }) => theme.borders.border1};
  box-shadow: ${({ theme }) => theme.shadows.shadow1};
  border-radius: 20px;

  .flatpickr-calendar.rangeMode.animate.inline {
    margin: 0 !important;
  }

  .flatpickr-day.inRange {
    background: #f7f7f7;
    border-color: #f7f7f7;
    border-radius: 50%;
    box-shadow: none;
  }

  .flatpickr-day.selected,
  .flatpickr-day.startRange,
  .flatpickr-day.endRange,
  .flatpickr-day.selected.inRange,
  .flatpickr-day.startRange.inRange,
  .flatpickr-day.endRange.inRange,
  .flatpickr-day.selected:focus,
  .flatpickr-day.startRange:focus,
  .flatpickr-day.endRange:focus,
  .flatpickr-day.selected:hover,
  .flatpickr-day.startRange:hover,
  .flatpickr-day.endRange:hover,
  .flatpickr-day.selected.prevMonthDay,
  .flatpickr-day.startRange.prevMonthDay,
  .flatpickr-day.endRange.prevMonthDay,
  .flatpickr-day.selected.nextMonthDay,
  .flatpickr-day.startRange.nextMonthDay,
  .flatpickr-day.endRange.nextMonthDay {
    background: #e61e29;
    border-color: #e61e29;
    color: #fff;
    border-radius: 50%;
    box-shadow: none !important;
  }

  .flatpickr-day.selected.endRange,
  .flatpickr-day.startRange.endRange,
  .flatpickr-day.endRange.endRange,
  .flatpickr-day.selected.startRange,
  .flatpickr-day.startRange.startRange {
    border-radius: 50%;
  }

  .flatpickr-day.today {
    border-color: #e61e29;
  }

  .flatpickr-calendar {
    border: none;
    border: none;
    box-shadow: none;
  }

  .flatpickr-weekday {
    font-weight: normal !important;
  }

  .flatpickr-weekdays {
    height: 50px;
  }

  .flatpickr-input {
    display: none;
    pointer-events: none;
  }

  .flatpickr-current-month .flatpickr-monthDropdown-months {
    -moz-appearance: none !important; /* Firefox */
    -webkit-appearance: none !important; /* Safari and Chrome */
    appearance: none !important;
  }

  .flatpickr-current-month .flatpickr-monthDropdown-months:hover {
    background: none;
    color: #e61e29;
  }
`;

const AdminDatePickerStyled = styled(Flatpickr)`
  width: 250px;
  border: none;
  border-radius: 200px;
  font-size: 15px;
  line-height: 18px;
  padding: 12.5px 24px 12.5px 16px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
  cursor: pointer;

  :focus {
    outline: none !important;
    /* border: 1px solid ${({ theme }) => theme.primary}; */
  }
`;

const StripRight = styled.div`
  width: 30px;
  height: 276px;
  border: 2px solid ${({ theme }) => theme.primary};
  border-radius: 0 20px 20px 0;
  border-left: 0;
  flex: none;
  display: flex;
`;

export const DatePicker = (props: DateTimePickerProps) => {
  return (
    <Div $width="100%" $flex $alignCenter style={{ flex: 'none' }}>
      <AdminDatePickerWrapperStyled>
        <AdminDatePickerStyled
          {...props}
          options={{
            dateFormat: 'M d, Y',
            inline: true,
            minDate: 'today',
          }}
          className="form-control"
        />
      </AdminDatePickerWrapperStyled>
      <Div $desktop>
        <StripRight />
      </Div>
    </Div>
  );
};
