'use client';

import { Typewriter } from 'react-simple-typewriter';

export const TypewriterComponent = () => {
  return (
    <Typewriter
      loop={0}
      cursor
      cursorStyle="|"
      words={[
        'Gets More Orders.',
        'Maximizes Grubhub.',
        'Dominates UberEats.',
        'Enhances DoorDash.',
        'Drives More Traffic.',
      ]}
    />
  );
};
