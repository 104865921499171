'use client';

import { usePathname } from 'next/navigation';
import { AnimatedDiv, AnimatedP } from '../animation/Animation';
import { ScrollDownStyled } from './scrollDown.styled';

export const ScrollDown = () => {
  const pathname = usePathname();

  const text =
    pathname === '/lp1' ? 'Scroll down and play the video' : 'Scroll for more';

  return (
    <ScrollDownStyled>
      <AnimatedDiv className="scroll-downs">
        <div className="mousey">
          <div className="scroller"></div>
        </div>
      </AnimatedDiv>
      <AnimatedP $center>{text}</AnimatedP>
    </ScrollDownStyled>
  );
};
