'use client';

import { cssTransition, toast } from 'react-toastify';
import { ToastError, ToastSuccess, X } from '../../assets';
import { lightTheme } from '../../styles';
import { IconMessageWrap, IconWrap } from './toastsStyled';

const move = cssTransition({
  enter: 'move-in',
  exit: 'move-out',
});

const toastConfig = {
  icon: false,
  hideProgressBar: true,
  pauseOnHover: true,
  autoClose: 5000,
  draggable: true,
  closeOnClick: true,
  closeButton: <X fill={lightTheme.darkGray + lightTheme.transparency.t50} />,
  transition: move,
};

export const SuccessToast = (successMessage?: string) => {
  toast.success(
    <SuccessToastComponent successMessage={successMessage ?? 'Success'} />,
    toastConfig
  );
};

export const ErrorToast = (errorMessage?: string) => {
  toast.error(
    <ErrorToastComponent errorMessage={errorMessage ?? 'Error'} />,
    toastConfig
  );
};

export const SuccessToastComponent = ({
  successMessage,
}: {
  successMessage?: string;
}) => (
  <>
    <IconMessageWrap>
      <IconWrap>
        <ToastSuccess />
      </IconWrap>
      <div>
        <h5>Success</h5>
        <p>{successMessage}</p>
      </div>
    </IconMessageWrap>
  </>
);

export const ErrorToastComponent = ({
  errorMessage,
}: {
  errorMessage?: string;
}) => (
  <>
    <IconMessageWrap>
      <IconWrap>
        <ToastError />
      </IconWrap>
      <div>
        <h5>Error</h5>
        <p>{errorMessage}</p>
      </div>
    </IconMessageWrap>
  </>
);
