import { useLayoutEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { AnimatedDiv, Spinner } from '..';
import { Div } from '../../styles';

export const VgrubsVideoAdd = () => {
  const [size, setSize] = useState({ width: null, height: null });
  const ref = useRef(null);
  const [loading, setLoading] = useState(true);

  useLayoutEffect(() => {
    setSize({
      width: ref?.current?.clientWidth,
      height: ref?.current?.clientWidth * (9 / 16),
    });
  }, []);

  return (
    <Div $textAlignCenter $width="100%" ref={ref}>
      {loading && <Spinner />}
      <AnimatedDiv ref={ref} $flex $alignCenter $justifyCenter>
        <ReactPlayer
          onReady={() => setLoading(false)}
          url="https://www.youtube.com/watch?v=u0csymTkFPo"
          width={size.width}
          height={size.height}
          controls={true}
          style={{ borderRadius: 25 }}
          playing={true}
        />
      </AnimatedDiv>
    </Div>
  );
};
