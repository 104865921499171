// import { useEffect } from 'react';
import styled from 'styled-components';

export const RadioComponent = styled.div`
  width: 24px;
  height: 24px;
  border: 2px solid inset ${({ theme }) => theme.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  -webkit-box-shadow: inset 0px 0px 0px 2px
    ${({ theme, checked }) => (checked ? theme.primary : theme.lightGray)};
  -moz-box-shadow: inset 0px 0px 0px 2px
    ${({ theme, checked }) => (checked ? theme.primary : theme.lightGray)};
  box-shadow: inset 0px 0px 0px 2px
    ${({ theme, checked }) => (checked ? theme.primary : theme.lightGray)};

  cursor: pointer;

  span {
    width: 12px;
    height: 12px;
    background-color: ${({ theme }) => theme.primary};
    border-radius: 50%;
  }
`;

export const Radio = ({ checked, setChecked }) => {
  return (
    <RadioComponent checked={checked} onClick={() => setChecked(!checked)}>
      {checked && <span></span>}
    </RadioComponent>
  );
};
