'use client';

import { useState } from 'react';
import { AnimatedDiv } from '../../components';
import { RangeSlider } from '../../components/rangeSlider/rangeSlider';
import { formatter } from '../../helpers';
import {
  Calculator,
  CalculatorBottom,
  Disclaimer,
  Div,
  H3,
  H5,
  lightTheme,
  P,
} from '../../styles';

export const GrowthCalculator = () => {
  const [averageOrder, setAverageOrder] = useState([26]);
  const [ordersPerDay, setOrdersPerDay] = useState([38]);
  // const [canceledOrdersPerDay, setCanceledOrdersPerDay] = useState([5]);

  const calculation =
    averageOrder * ordersPerDay * 365 * 0.18 +
    averageOrder * ordersPerDay * 365 * 0.18 * 0.03;

  return (
    <AnimatedDiv>
      <H3 $mb="24" $mbLG="48" $mbSM="12">
        <span>vGrubs</span> Revenue Growth Calculator
      </H3>
      <P>
        Input your daily order figures, and our calculator will approximate how
        much additional revenue your business will receive with vGrubs on an
        annual basis.
      </P>
      <Calculator>
        <Div $p="24px 30px" $pLG="20px 30px">
          <Div $flex $gap="50px" $gapLG="100px" $gapSM="25px" $columnSM>
            <Div $width="50%" $widthSM="100%">
              <P $mb="15" $mbLG="30">
                Average Order Dollar Amount:
              </P>
              <Div $flex $alignCenter $columnSM $gapSM="15px">
                <RangeSlider range={averageOrder} setRange={setAverageOrder} />
                <Div
                  $flex
                  $alignCenter
                  $minWidth="100px"
                  $minWidthLG="200px"
                  $justifyCenter
                >
                  <H5 $m="0" $mLG="0">
                    <span>${averageOrder}</span>
                  </H5>
                  <Disclaimer $m="0" $mLG="0">
                    /100
                  </Disclaimer>
                </Div>
              </Div>
            </Div>
            <Div $width="50%" $widthSM="100%">
              <P $mb="15" $mbLG="30">
                Typical Delivery Orders Per Day:
              </P>
              <Div $flex $alignCenter $columnSM $gapSM="15px">
                <RangeSlider range={ordersPerDay} setRange={setOrdersPerDay} />
                <Div
                  $flex
                  $alignCenter
                  $minWidth="100px"
                  $minWidthLG="200px"
                  $justifyCenter
                >
                  <H5 $m="0" $mLG="0">
                    <span>{ordersPerDay}</span>
                  </H5>
                  <Disclaimer $m="0" $mLG="0">
                    /100
                  </Disclaimer>
                </Div>
              </Div>
            </Div>
            {/* <Div $width='50%' $widthSM='100%'>
              <P $mb='15' $mbLG='30'>
                Average Canceled Orders Per Day
              </P>
              <Div $flex $alignCenter $columnSM $gapSM='15px'>
                <RangeSlider
                  range={canceledOrdersPerDay}
                  setRange={setCanceledOrdersPerDay}
                  max={30}
                />
                <Div
                  $flex
                  $alignCenter
                  $minWidth='100px'
                  $minWidthLG='200px'
                  $justifyCenter
                >
                  <H5 $m='0' $mLG='0'>
                    <span>{canceledOrdersPerDay}</span>
                  </H5>
                  <Disclaimer $m='0' $mLG='0'>
                    /30
                  </Disclaimer>
                </Div>
              </Div>
            </Div> */}
          </Div>
        </Div>
        <CalculatorBottom>
          <P
            $m="0"
            $mLG="0"
            style={{
              fontWeight: '500',
              color: lightTheme.white,
              textTransform: 'uppercase',
            }}
          >
            <b>Average additional Income With vGrubs Per Year:</b>
          </P>
          <H3 $m="0" $mLG="0" style={{ color: lightTheme.white }}>
            {formatter.format(calculation)}
          </H3>
        </CalculatorBottom>
      </Calculator>
    </AnimatedDiv>
  );
};
