import styled from 'styled-components';
import { AnimatedDiv } from '../animation/Animation';

const SpinnerStyled = styled.div<{ fullscreen?: boolean }>`
  ${({ fullscreen, theme }) =>
    fullscreen &&
    `
      z-index: 10;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: ${theme.white + theme.transparency.t50};
`}
  display: flex;
  align-items: center;
  justify-content: center;
  @keyframes ldio-4ro331xvst8 {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  .ldio-4ro331xvst8 div {
    position: absolute;
    width: 46.98px;
    height: 46.98px;
    border: 5.22px solid ${({ theme }) => theme.primary};
    border-top-color: transparent;
    border-radius: 50%;
  }
  .ldio-4ro331xvst8 div {
    animation: ldio-4ro331xvst8 0.5681818181818182s linear infinite;
    top: 50px;
    left: 50px;
  }
  .loadingio-spinner-rolling-t61fu8i5jyi {
    width: 100px;
    height: 100px;
    display: inline-block;
    overflow: hidden;
  }
  .ldio-4ro331xvst8 {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-4ro331xvst8 div {
    box-sizing: content-box;
  }
  /* generated by https://loading.io/ */
`;

export const Spinner = ({ fullscreen }: { fullscreen?: boolean }) => {
  return (
    <AnimatedDiv key="iframe_spinner">
      <SpinnerStyled fullscreen={fullscreen}>
        <div className="loadingio-spinner-rolling-t61fu8i5jyi">
          <div className="ldio-4ro331xvst8">
            <div></div>
          </div>
        </div>
      </SpinnerStyled>
    </AnimatedDiv>
  );
};
