import styled from 'styled-components';
import { Disclaimer, Div } from '../../styles';

const SelectStyled = styled.select`
  position: relative;
  width: 100%;
  /* border: 1px solid ${({ theme }) => theme.gray}; */
  background: none;
  border-radius: 200px;

  border: none;
  font-size: 14px;
  line-height: 18px;
  padding: 0 24px;
  height: 40px;
  display: flex;
  align-items: center;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  color: ${({ theme }) => theme.darkGray};
  background: ${({ theme }) => theme.white};

  ${({ calendar }) =>
    calendar &&
    `
    padding-left: 48px;
   `};

  ${({ chevron }) =>
    chevron &&
    `
    padding-right: 48px;
   `};

  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
  cursor: pointer;

  :focus {
    outline: none !important;
    /* border: 1px solid ${({ theme }) => theme.primary}; */
  }

  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
`;

const SelectWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.darkGray}80;
  border-radius: 200px;
  position: relative;
  width: 100%;

  ${({ shadowDesign, theme }) =>
    shadowDesign &&
    `
    border: 1px solid #e3e3e3;
    box-shadow: ${theme.shadows.shadow1};
  `}
`;

const IconStyled = styled.div`
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 1;
  height: 32px;
`;

const ChevronStyled = styled(IconStyled)`
  position: absolute;
  right: 16px;
  left: auto;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 1;
  height: 16px;
`;

export const Select = (props) => {
  return (
    <Div $mb="20">
      {props.label && (
        <Disclaimer $mb="10">
          <b>{props.label}</b>
        </Disclaimer>
      )}

      <SelectWrapper shadowDesign={props.shadowDesign}>
        <div style={{ overflow: 'hidden' }}>
          <SelectStyled placeholder={props.placeholder} {...props}>
            {props.options}
          </SelectStyled>
        </div>
        {props.chevron && (
          <ChevronStyled>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.29365 12.7066C7.68428 13.0973 8.31865 13.0973 8.70928 12.7066L14.7093 6.70664C15.0999 6.31602 15.0999 5.68164 14.7093 5.29102C14.3187 4.90039 13.6843 4.90039 13.2937 5.29102L7.9999 10.5848L2.70615 5.29414C2.31553 4.90352 1.68115 4.90352 1.29053 5.29414C0.899902 5.68477 0.899902 6.31914 1.29053 6.70977L7.29053 12.7098L7.29365 12.7066Z"
                fill="#4C4C4C"
              />
            </svg>
          </ChevronStyled>
        )}
      </SelectWrapper>
    </Div>
  );
};
