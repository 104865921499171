import { AnimatedH1, AnimatedH4 } from '../';
import { Div } from '../../styles';
import { FrameWrapper } from '../iframeWrapper/iframeWrapper';

export const Testimonials = () => {
  return (
    <Div $width="100%">
      <AnimatedH4>testimonials</AnimatedH4>
      <AnimatedH1 $fw="400">
        <b>
          <span>Customer </span> Testimonials{' '}
        </b>{' '}
        from Google
      </AnimatedH1>
      <FrameWrapper
        class="lc_reviews_widget"
        src="https://services.leadconnectorhq.com/reputation/widgets/review_widget/WxoBGa1dix4V1Sx1488v"
        frameborder="0"
        scrolling="no"
        style={{ minWidth: '100%', width: '100%', height: 700 }}
      ></FrameWrapper>
    </Div>
  );
};
