import { Range } from 'react-range';

import { lightTheme } from '../../styles';

export const RangeSlider = ({ range, setRange, max }) => {
  return (
    <Range
      step={1}
      min={0}
      max={max ?? 100}
      values={range}
      onChange={(values) => setRange(values)}
      renderTrack={({ props, children }) => (
        <div
          {...props}
          style={{
            ...props.style,
            height: '3px',
            width: '100%',
            backgroundColor: lightTheme.primary,
          }}
        >
          {children}
        </div>
      )}
      renderThumb={({ props }) => (
        <div
          {...props}
          style={{
            ...props.style,
            height: '18px',
            width: '18px',
            borderRadius: '50%',
            backgroundColor: lightTheme.primary,
          }}
        />
      )}
    />
  );
};
