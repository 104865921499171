import styled from 'styled-components';
import { motion } from 'framer-motion';

export const ScrollDownStyled = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 80px;
  left: 50%;
  width: 100px;
  transform: translateX(-50%);

  @media (min-width: 3500px) {
    width: 200px;
  }

  p {
    font-size: 14px !important;
    margin-bottom: 0;

    @media (min-width: 3500px) {
      font-size: 28px !important;
    }
  }

  .mousey {
    width: 5px;
    padding: 5px 10px;
    height: 30px;
    border: 3px solid ${({ theme }) => theme.darkGray};
    border-radius: 25px;
    box-sizing: content-box;
    margin-bottom: 10px;

    @media (min-width: 3500px) {
      width: 10px;
      padding: 10px 20px;
      height: 60px;
      border: 6px solid ${({ theme }) => theme.darkGray};
      border-radius: 50px;
      margin-bottom: 20px;
    }
  }

  .scroller {
    width: 5px;
    height: 7px;
    border-radius: 25px;
    background-color: ${({ theme }) => theme.darkGray};
    animation-name: scroll;
    animation-duration: 1.5s;
    animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
    animation-iteration-count: infinite;

    @media (min-width: 3500px) {
      width: 10px;
      height: 14px;
      border-radius: 50px;
      background-color: ${({ theme }) => theme.darkGray};
      animation-name: scroll;
      animation-duration: 1.5s;
      animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
      animation-iteration-count: infinite;
    }
  }

  @keyframes scroll {
    0% {
      opacity: 0;
    }
    10% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(15px);
      opacity: 0;

      @media (min-width: 3500px) {
        transform: translateY(30px);
      }
    }
  }

  @media (max-width: 1000px) and (orientation: landscape) {
    display: none;
  }

  @media (max-width: 800px) {
    display: none;
  }
`;
